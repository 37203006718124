body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the alpha value for opacity */
  z-index: 9999; /* Ensure it's positioned above other elements */
}
#player {
  /* background-color: white; */
  /* padding: 20px; */
  border-radius: 10px;
}

div#root {
  background-color: #1e1925;
  min-height: 100vh;
}

p.story-text {
  background-color: #202519;
}

div#PlayStory{
  padding: 10px;
}